import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { LanguageProvider } from './providers/LanguageContext';
import ZeContent from './ZeContent';
import { Languages } from 'common/language';

const root = ReactDOM.createRoot(
	document.getElementById('ttt-content') as HTMLElement
);
root.render(
	<React.StrictMode>
		<ZeApp />
	</React.StrictMode>
);

function ZeApp() {
	const path = window.location.pathname;
	const rootPath = '/';
	const frPath = `/${Languages.fr}`;
	const nlPath = `/${Languages.nl}`;

	const validPaths = [rootPath, frPath, nlPath];
	if (!validPaths.includes(path)) {
		window.location.href = rootPath;
		return <></>;
	}

	const lang = (path === nlPath) ? Languages.nl
		: (path === frPath) ? Languages.fr
			: undefined;

	return <>
		<LanguageProvider lang={lang}>
			<ZeContent />
		</LanguageProvider>
	</>
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
