import React from 'react';

import { TranslatedItem } from 'common/language';
import { useLanguage } from '../providers/LanguageContext';

export function Translatable({ children }: { children: TranslatedItem<React.ReactNode> }) {
	const { language } = useLanguage();

	return <>{children[language]}</>;
}

export default Translatable;
