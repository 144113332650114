import { useState } from "react";

import Log from "common/logger";
import { TranslatedItem } from "common/language";
import { apiRequest, ensureInt, getExceptionDetails, isEmailValid, newLog, nullOrWhitespaceTrim } from "./utils";
import * as routes from "./routes";

import { useLanguage } from "./providers/LanguageContext";
import * as icons from "./media/icons";

export function ZeForm() {
	const { translate } = useLanguage();

	const [nom, setNom] = useState('');
	const [nomRequired, setNomRequired] = useState(false);
	const [prenom, setPrenom] = useState('');
	const [prenomRequired, setPrenomRequired] = useState(false);
	const [email, setEmail] = useState('');
	const [emailRequired, setEmailRequired] = useState(false);
	const [adresse, setAdresse] = useState('');
	const [codePostal, setCodePostal] = useState<number | null>(null);
	const [viendra, setViendra] = useState(false);
	const [accompagne, setAccompagne] = useState(false);

	const [captchaSvg, setCaptchaSvg] = useState('');
	const [captchaKey, setCaptchaKey] = useState('');
	const [captchaText, setCaptchaText] = useState('');
	const [captchaRequired, setCaptchaRequired] = useState(false);

	const [errors, setErrors] = useState<null | string[]>(null);

	const [sending, setSending] = useState(false);
	const [sent, setSent] = useState(false);

	async function handleRequestCaptcha() {
		const log = newLog('captchaReq');
		await requestCaptcha({
			log,
			origKey: captchaKey,
			translate,
			setCaptchaSvg,
			setCaptchaKey,
			setErrors,
		});
	}

	async function handleSend() {
		const log = newLog('send');
		let valid = true;
		setErrors(null);

		if (nullOrWhitespaceTrim(nom) === null) {
			setNomRequired(true);
			valid = false;
		}
		else {
			setNomRequired(false);
		}

		if (nullOrWhitespaceTrim(prenom) === null) {
			setPrenomRequired(true);
			valid = false;
		}
		else {
			setPrenomRequired(false);
		}

		if (!isEmailValid(email)) {
			setEmailRequired(true);
			valid = false;
		}
		else {
			setEmailRequired(false);
		}

		if ((nullOrWhitespaceTrim(captchaKey) === null) || ((nullOrWhitespaceTrim(captchaText) ?? '').length !== 4)) {
			setCaptchaRequired(true);
			valid = false;
			if (nullOrWhitespaceTrim(captchaKey) === null)
				requestCaptcha({
					log,
					origKey: captchaKey,
					translate,
					setCaptchaSvg,
					setCaptchaKey,
					setErrors,
				});
		} else {
			setCaptchaRequired(false);
		}

		if (!valid)
			return;

		try {
			setSending(true);

			const rv = await sendForm({
				log,
				form: {
					nom,
					prenom,
					email,
					adresse,
					codePostal,
					viendra,
					accompagne: viendra && accompagne,

					captchaKey,
					captchaText,
				},
				translate,
			});
			if (rv === null) {
				setSent(true);
			} else if (rv === 'failcaptcha') {
				setCaptchaRequired(true);
				setErrors([translate({
					fr: 'La vérification du captcha a échoué. Veuillez réessayer.',
					nl: 'Captcha-verificatie mislukt. Probeer het opnieuw.',
				})]);
			} else {
				setErrors(rv);
			}
		} finally {
			setSending(false);
		}
	}

	const lblNom = translate({
		fr: 'Nom',
		nl: 'Naam',
	});
	const lblPrenom = translate({
		fr: 'Prénom',
		nl: 'Voornaam',
	});
	const lblEmail = translate({
		fr: 'Adresse e-mail',
		nl: 'E-mail adres',
	});
	const lblQuestion = translate({
		fr: 'Posez une question ?',
		nl: 'Een vraag stellen?',
	});
	const lblAdresse = translate({
		fr: 'Adresse',
		nl: 'Adres',
	});
	const lblCodePostal = translate({
		fr: 'Code postal',
		nl: 'Postcode',
	});
	const lblViendrai = translate({
		fr: 'Je viendrai à la réunion',
		nl: 'Ik kom naar de bijeenkomst',
	});
	const lblAccompagné = translate({
		fr: 'Je viendrai accompagné',
		nl: 'Ik kom vergezeld',
	});
	const lblIAmNoRobot = translate({
		fr: 'Je ne suis pas un robot',
		nl: 'Ik ben geen robot',
	});
	const lblCaptchaText = translate({
		fr: 'Tapez les caractères',
		nl: 'Typ de tekens',
	});
	const lblCaptchaRequired = translate({
		fr: 'Veuillez résoudre ce captcha',
		nl: 'Los deze captcha op',
	});
	const lblFieldRequired = translate({
		fr: 'Veuillez remplir le champ.',
		nl: 'Vul het veld in.',
	});
	const lblMailRequired = translate({
		fr: 'Veuillez entrer une adresse mail correcte.',
		nl: 'Voer een correct e-mailadres in.',
	});
	const lblSend = translate({
		fr: 'Envoyer mes données',
		nl: 'Stuur mijn gegevens',
	});
	const lblSent = translate({
		fr: 'Votre inscription a bien été enregistrée !',
		nl: 'Uw registratie is succesvol geregistreerd!',
	});
	const lblError = translate({
		fr: `Une erreur est survenue lors de l'envoi du formulaire:`,
		nl: 'Er is een fout opgetreden bij het verzenden van het formulier:',
	});

	return <>
		<div className="row gx-3">
			<div className="col-sm-6">
				<div className="mb-3">
					<label className="form-label visually-hidden" htmlFor="signupFormLastName">{lblNom}</label>
					<input type="text" className="form-control form-control-lg" name="signupFormLastName" id="signupFormLastName" placeholder={lblNom} aria-label={lblNom}
						value={nom}
						onChange={(e) => setNom(e.target.value)}
					/>
					<span className="invalid-feedback"
						style={{ display: nomRequired ? 'inherit' : undefined }}
					>
						{lblFieldRequired}
					</span>
				</div>
			</div>

			<div className="col-sm-6">
				<div className="mb-3">
					<label className="form-label visually-hidden" htmlFor="signupFormFirstName">{lblPrenom}</label>
					<input type="text" className="form-control form-control-lg" name="signupFormFirstName" id="signupFormFirstName" placeholder={lblPrenom} aria-label={lblPrenom}
						value={prenom}
						onChange={(e) => setPrenom(e.target.value)}
					/>
					<span className="invalid-feedback"
						style={{ display: prenomRequired ? 'inherit' : undefined }}
					>
						{lblFieldRequired}
					</span>
				</div>
			</div>
		</div>

		<div className="mb-3">
			<label className="form-label visually-hidden" htmlFor="signupFormEmail">{lblEmail}</label>
			<input type="email" className="form-control form-control-lg" name="signupFormEmailName" id="signupFormEmail" placeholder={lblEmail} aria-label={lblEmail}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
			/>
			<span className="invalid-feedback"
				style={{ display: emailRequired ? 'inherit' : undefined }}
			>
				{lblMailRequired}
			</span>
		</div>

		<div className="mb-3">
			<label className="form-label visually-hidden" htmlFor="signupAdresse">{lblAdresse}</label>
			<textarea className="form-control" placeholder={lblAdresse} id="signupAdresse"
				value={adresse}
				onChange={(e) => setAdresse(e.target.value)}
			/>
		</div>

		<div className="row gx-3">
			<div className="col-sm-6">
				<div className="mb-3">
					<label className="form-label visually-hidden" htmlFor="signupFormCodePostal">{lblCodePostal}</label>
					<input type="text" className="form-control form-control-lg" name="signupFormCodePostal" id="signupFormCodePostal" placeholder={lblCodePostal} aria-label={lblCodePostal}
						value={(codePostal == null) ? '' : `${codePostal}`}
						onChange={(e) => setCodePostal(ensureInt({ str: e.target.value, original: codePostal }))}
					/>
				</div>
			</div>
		</div>

		<div className="mb-3">
			<input type="checkbox" className="form-check-input" name="ViendraiCheckboxName" id="signupFormViendrai" aria-label={lblViendrai}
				checked={viendra}
				onChange={(e) => setViendra(e.target.checked)}
			/>
			&nbsp;
			<label className="form-label" htmlFor="signupFormViendrai">{lblViendrai}</label>
		</div>

		{viendra && <div className="mb-3">
			<input type="checkbox" className="form-check-input" name="AccompagneCheckboxName" id="signupFormAccompagne" aria-label={lblAccompagné}
				checked={accompagne}
				onChange={(e) => setAccompagne(e.target.checked)}
			/>
			&nbsp;
			<label className="form-label" htmlFor="signupFormAccompagne">{lblAccompagné}</label>
		</div>}

		<div className="row gx-3">
			<div className="col-sm-6">
				<div className="mb-3">
					{captchaSvg === '' && <>
						<input type="checkbox" className="form-check-input" name="IAmNoRobotCheckboxName" id="signupFormIAmNoRobot" aria-label={lblIAmNoRobot}
							checked={false}
							onChange={() => handleRequestCaptcha()}
						/>
						&nbsp;
						<label className="form-label" htmlFor="signupFormIAmNoRobot">{lblIAmNoRobot}</label>
					</>}
					{captchaSvg !== '' && <>
						<span dangerouslySetInnerHTML={{ __html: captchaSvg }}></span>

						<button type="button" className="btn btn-outline-secondary btn-sm"
							onClick={() => handleRequestCaptcha()}
						>
							<icons.Refresh style={{ width: '2em', height: '2em' }} />
						</button>
					</>}
				</div>
			</div>

			<div className="col-sm-6">
				<div className="mb-3">
					{captchaSvg !== '' && <>

						<label className="form-label visually-hidden" htmlFor="signupFormCaptchaText">{lblCaptchaText}</label>
						<input type="text" className="form-control form-control-lg" name="signupFormCaptchaText" id="signupFormCaptchaText" placeholder={lblCaptchaText} aria-label={lblCaptchaText}
							value={captchaText}
							onChange={(e) => setCaptchaText(e.target.value)}
						/>
					</>}
					<span className="invalid-feedback"
						style={{ display: captchaRequired ? 'inherit' : undefined }}
					>
						{lblCaptchaRequired}
					</span>
				</div>
			</div>
		</div>

		<div className="d-grid">
			{sending ?
				<button type="submit" className="btn btn-primary btn-lg" disabled>
					<icons.LoadingSpinnerButton />
				</button>
				: sent ?
					<button type="submit" className="btn btn-outline-primary btn-lg" disabled>
						<icons.SendSuccess style={{ color: 'green', verticalAlign: 'baseline' }} />
						&nbsp;
						<i>{lblSent}</i>
					</button>
					: <button type="submit" className="btn btn-primary btn-lg"
						onClick={handleSend}
					>
						{lblSend}
					</button>
			}

			{(errors !== null) && <i>
				<span style={{ color: 'red' }}>{lblError}</span>
				<ul style={{ listStyleType: 'none' }}>
					{errors.map((error, index) => (
						<li key={index} style={{ color: 'red' }}>{error}</li>
					))}
				</ul>
			</i>}
		</div>
	</>
}

async function requestCaptcha({ log, origKey, translate, setCaptchaSvg, setCaptchaKey, setErrors }: {
	log: Log,
	origKey: string,
	translate: (trs: TranslatedItem<string>) => string,
	setCaptchaSvg: (s: string) => void,
	setCaptchaKey: (s: string) => void,
	setErrors: (s: string[]) => void,
}) {
	try {
		log.log('invoke apiRequest()');
		const { key, svg } = await apiRequest<routes.api.test.CaptchaResult>({
			method: 'POST',
			url: routes.api.test.captcha(),
			body: {
				discardKey: (nullOrWhitespaceTrim(origKey) === null) ? null : origKey,
			},
			exceptionMessage: translate({
				fr: `Erreur lors de la demande de captcha au serveur`,
				nl: 'Fout bij het aanvragen van captcha van de server',
			}),
		});

		setCaptchaSvg(svg);
		setCaptchaKey(key);
	} catch (err) {
		log.error(err);
		const msg = await getExceptionDetails(err);
		setErrors([msg]);
	}
}

async function sendForm({ log, form, translate }: {
	log: Log,
	form: routes.api.test.SendFormRequest,
	translate: (trs: TranslatedItem<string>) => string,
}): Promise<null | 'failcaptcha' | string[]> {
	try {
		log.log('invoke apiRequest()');
		const rc = await apiRequest<routes.api.test.SendFormResult>({
			method: 'POST',
			url: routes.api.test.sendForm(),
			body: form,
			exceptionMessage: translate({
				fr: `Erreur lors de la requète au serveur`,
				nl: 'Fout bij het opvragen van de server',
			}),
		});

		if (rc.captchaFailed) {
			log.log('captcha failed');
			return 'failcaptcha';
		}

		log.log('success');
		return null;
	} catch (err) {
		log.error(err);
		const msg = await getExceptionDetails(err);
		return [msg];
	}
}

export default ZeForm;
