import lodashIsEqual from "lodash.isequal";

import Log from "./logger";
import { ApiResponse } from "./dataStores";

/** Syntax hack for use in "xxx ?? throwError('error')" situations */
export function throwError(message: string, log?: Log): never {
var HERE = 123;//TODO: Add `log` to Error & allow to display it in the errors handler
	throw new Error(message);
}

/** Converts the specified date to a string of type 'YYYY-MM-DD' or 'YYYY-MM-DD HH:mm:ss.SSS' */
export function dateString({ d, withTime = true }: { d: Date, withTime?: boolean }): string {
	// Original
	// return moment( d ).format( 'YYYY-MM-DD HH:mm:ss.SSS' );

	// Chat-GPT rewritten:
	const pad = (num: number): string => num.toString().padStart(2, '0');
	const padMilliseconds = (num: number): string => num.toString().padStart(3, '0');
	let str = `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}`;
	if (withTime)
		str += ` ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}.${padMilliseconds(d.getMilliseconds())}`;
	return str;
}

/** Parse the specified string and return the corresponding 'Date' */
export function dateValue({ str }: { str: string }): Date | null {
	const parts = str.split(/[T ]/); // Split date and time parts
	const datePart = parts[0].split('-').map(Number); // Split date into year, month, and day
	const timePart = parts[1] ? parts[1].split(':').map(Number) : [0, 0, 0]; // Split time into hours, minutes, and seconds
	const milliseconds = parts[2] ? parseInt(parts[2].split('.')[1]) : 0; // Extract milliseconds if present

	// Check if all parts are valid numbers
	if (
		datePart.length !== 3 ||
		timePart.length !== 3 ||
		datePart.some(isNaN) ||
		timePart.some(isNaN) ||
		isNaN(milliseconds)
	) {
		return null; // Return null if any part is invalid
	}

	// Create and return a new Date object
	return new Date(datePart[0], datePart[1] - 1, datePart[2], timePart[0], timePart[1], timePart[2], milliseconds);
}

/** Function to simulate string-valued enums
 * Based on: https://basarat.gitbooks.io/typescript/docs/types/literal-types.html
 * Returns: { e:the enum , a:the array specified as parameter } */
export function strEnum<T extends string>(a: Array<T>): { e: { [K in T]: K }, a: T[] } {
	const e = a.reduce((res, key) => {
		res[key] = key;
		return res;
	}, Object.create(null));
	return { e, a };
}

export function ensureEnum<T extends string>(s: string, l: T[]): T | null {
	for (const item of l)
		if (item === s)
			return item;
	return null;
}

export function ensureInt({ str, original = null }: { str: string | null, original?: number | null }): number | null {
	str = nullOrWhitespaceTrim(str);
	if (str == null)
		return null;
	const n = parseInt(str);
	if (isNaN(n))
		return original;
	return n;
}

export function nameof<T>(name: keyof T): string {
	return name as string;
}

export function exceptionToString(p: { ex: Error, includeStackTrace?: boolean }): string {
	const msg = `${p.ex.message}`;
	if ((p.includeStackTrace ?? false) && p.ex.stack)
		return `${msg}\nStack Trace:\n${p.ex.stack}`;
	return msg;
}

export function generateId(): string {
	return '_' + generatePassword(8);
}

export function generatePassword(length?: number, charset?: string): string {
	length ??= 40;
	charset ??= 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	let str = '';
	const n = charset.length;
	for (var i = 0; i < length; ++i) {
		var j = (Math.random() * n) | 0;
		str += charset.charAt(j);
	}
	return str;
}

export function sleep(miliseconds: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, miliseconds));
}

export function deepClone<T>(src: T): T {
	return JSON.parse(JSON.stringify(src));
}

export function areEqual(a: any, b: any) {
	return lodashIsEqual(a, b);
}

export function arrayToHashset<T>(arr: T[], filter?: (v: T) => boolean): Set<T> {
	const s = new Set<T>();
	arr.forEach((v) => {
		const addMe = (filter == null) ? true : filter(v);
		if (addMe)
			s.add(v);
	});
	return s;
}

export function nullOrWhitespaceTrim(input: string | null | undefined): string | null {
	if (input == null)
		return null;
	input = input.trim();
	if (input === '')
		return null;
	return input;
}

/** Utility function to remove accents/diacritics from a string */
export function normalizeString(input: string): string;
export function normalizeString(input: null): null;
export function normalizeString(str: string | null): string | null {
	if (str == null)
		return null;
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

export function stringFilterMatches(filter: string | null, target: string | null | undefined): boolean {
	filter = nullOrWhitespaceTrim(filter)
	if (filter === null)
		// Filter not active => always accept
		return true;

	if (target == null)
		// Filter active but target not available => always reject
		return false;

	// Split the name into tokens, lowercase and remove accents etc.
	const filterTokens = normalizeString(filter).split(/\s+/);
	const targetNorm = normalizeString(target);
	return filterTokens.every(token => targetNorm.includes(token));
}

export function trimStart(str: string, c: string) {
	if (str.startsWith(c))
		return str.slice(c.length);
	return str;
}

export function trimEnd(str: string, c: string) {
	if (str.endsWith(c))
		return str.slice(0, -c.length);
	return str;
}

export function isEmailValid(email: string): boolean {
	const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
	return emailRegex.test(email);
}

export function urlJoin(a: string, b: string) {
	a = trimEnd(a, '/');
	b = trimStart(b, '/');
	return `${a}/${b}`;
}

export function queryUrl(url: string, parms: { [k: string]: undefined | string | number | boolean | Date }): string {
	const parts = new URLSearchParams();
	Object.entries(parms).forEach(([k, v]) => {
		if (v == null)
			return;
		if (v instanceof Date)
			v = v.toISOString();
		parts.set(k, v as any);
	});

	if (parts.size === 0)
		return url;

	return `${url}?${parts.toString()}`;
}

export async function shieldException<T>(
	log: Log,
	callback: (log: Log) => Promise<T>,
	finaly?: (rv: T | 'error', error: unknown) => void | Promise<void>,
) {
	let rv: T | 'error' = 'error';
	let error: unknown = null;
	try {
		rv = await callback(log);
	}
	catch (err) {
		log.exception(err);
		error = err;
	} finally {
		if (finaly != null) {
			const frv = finaly(rv, error);
			if (frv instanceof Promise)
				await frv;
		}
	}
	return rv;
}

export async function apiRequest<T>({ url, method = 'GET', exceptionMessage, body }: {
	url: string,
	method?: 'GET' | 'PUT' | 'POST' | 'DELETE',
	exceptionMessage: string,
	body?: { [k: string]: any },
}): Promise<T> {
	const fetchParms: RequestInit = {
		method,
		headers: { 'Content-Type': 'application/json' },
	};
	if (body != null)
		fetchParms.body = JSON.stringify(body);

	const response = await fetch(url, fetchParms);
	if (!response.ok)
		throw new ApiError(`Request to server terminated with error status '${response.status}'`, response);
	const result = await response.json() as ApiResponse;
	if (result.success !== true) {
		if (result.error != null)
			exceptionMessage = `${exceptionMessage} ; ${result.error}`;
		throw new ApiError(exceptionMessage, response);
	}

	return result as T;
}
export class ApiError extends Error {
	constructor(
		msg: string,
		public readonly response: Response,
	) {
		super(msg);
	}

	public async tryGetApiError() {
		try {
			return await this.response.json() as ApiResponse;
		}
		catch {
			return null;
		}
	}
}

export async function getExceptionDetails(error: any) {
	if (error instanceof ApiError) {
		const res = await error.tryGetApiError();
		if (res?.error != null) {
			console.error('ApiError', res);
			return res.error;
		}
	}

	if (error instanceof Error) {
		return (error.stack != null) ? error.stack : `${error.name}: ${error.message}`;
	} else {
		return String(error);
	}
}
