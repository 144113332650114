import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

import * as utils from '../utils';
import { Language, TranslatedItem } from 'common/language';

export const defaultLanguage: Language = 'fr';

const LanguageContext = createContext<{
	language: Language;
	setLanguage: (language: Language) => void;
	translate: (trs: TranslatedItem<string>) => string;
}>(null!);

export const useLanguage = () => useContext(LanguageContext) ?? utils.throwError(`'useLanguage()' must be used within a 'LanguageProvider'`);

export function LanguageProvider({ children, lang }: { children: ReactNode, lang?: Language }) {
	const [language, setLanguage] = useState<Language>(() => {
		if (lang != null)
			// forced language (eg. reached "/en" URL) => update settings if required
			return lang;

		return lang ?? getStorageLanguage() ?? utils.getBrowserLanguage() ?? defaultLanguage;
	});

	// store language whenever it changes
	useEffect(() => {
		setStorageLanguage(language);
	}, [language]);

	const translate = (trs: TranslatedItem<string>) => trs[language];

	// update <html lang="XX">
	document.documentElement.lang = language;

	return (
		<LanguageContext.Provider value={{ language, setLanguage, translate }}>
			{children}
		</LanguageContext.Provider>
	);
}

const storageKey = 'language';
function getStorageLanguage() {
	return localStorage.getItem(storageKey) as Language | null;
}
function setStorageLanguage(language: Language) {
	const fallback = utils.getBrowserLanguage() ?? defaultLanguage;
	if (language === fallback)
		// setting would have no effect => remove
		localStorage.removeItem(storageKey);
	else
		localStorage.setItem(storageKey, language);
}
