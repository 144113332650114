import Translatable from "./components/Translatable";
import ZeForm from "./ZeForm";

export function ZeContent() {
	return <>
		<div className="col-md-6 col-lg-5 order-md-2">

			<Translatable>{{
				fr: <>
					<div className="mb-4">
						<h1>
							<span className="text-primary"></span>Invitation
						</h1>
						<p>
							Mesdames, Messieurs<br />
							Chères voisines, Chers Voisins,
						</p>
						<p>
							Bienvenu sur la plateforme de réservation pour la visite de la D'Ieteren Gallery du 1er février 2025, 11h.<br />
							Nous vous souhaitons le meilleur pour cette nouvelle année !
						</p>
						<p>
							Bien cordialement,
						</p>
						<p>
							Steven Boel & Pierre Lalmand
						</p>
					</div>
				</>,
				nl: <>
					<div className="mb-4">
						<h1>
							<span className="text-primary"></span>Uitnodiging
						</h1>
						<p>
							Dames en Heren<br />
							Beste buren,
						</p>
						<p>
							Welkom op het reserveringsplatform voor uw bezoek aan de D'Ieteren Gallery op 1 februari 2025, 11 uur.<br />
							Wij wensen u het allerbeste voor dit nieuwe jaar !
						</p>
						<p>
							Hartelijke groeten,
						</p>
						<p>
							Steven Boel & Pierre Lalmand
						</p>
					</div>
				</>
			}}</Translatable>

			<ZeForm />
		</div>
	</>
}

export default ZeContent;
