import { strEnum } from "./utils";

export const { e: Languages, a: allLanguages } = strEnum(['fr', 'nl']);
export type Language = keyof typeof Languages;
export type TranslatedItem<T> = {
	[key in Language]: T;
}
export type TranslatedString = TranslatedItem<string>;

export const languageNames: TranslatedString = {
	fr: 'Français',
	nl: 'Nederlands',
};
